<template>
	<div style="width: 100%;min-height: 100vh;background-image: linear-gradient(white, #b5d6ff);">
		<div>
			<img style="width: 80%;margin-left: 11%;margin-top: 5%" src="https://czsc.oss-cn-beijing.aliyuncs.com/bj/material_turnover.png"/>
		</div>
	<!--	新增周转材料-->
		<div style="margin-top: 20%;width: 90%;margin-left: 5%">
			<el-form
				label-position="right"
				label-width="auto"
				:model="formLabelAlign"
			>
				<el-form-item label="材料名称">
					<el-input v-model="formLabelAlign.material_name" />
				</el-form-item>
				<el-form-item label="材料单位">
					<el-input v-model="formLabelAlign.material_unit" />
				</el-form-item>
				<el-form-item label="规格型号">
					<el-input v-model="formLabelAlign.material_model" />
				</el-form-item>
				<el-form-item label="材料数量">
					<el-input v-model="formLabelAlign.material_num" />
				</el-form-item>
				<el-form-item label="材料照片">
					<input type="file" accept="image/*" @change="handleImageCapture">
				</el-form-item>
				<el-form-item label="闲置原因">
					<input type="file" accept="" @change="handleImageCaptureFile">
				</el-form-item>
			</el-form>
		</div>
	<!--	图片上传-->
		<div style="width: 100%">
			<!--<el-upload-->
			<!--	class="upload-demo"-->
			<!--	action="https://www.cr12cz.cn/czscsms/up/uploadMaterial"-->
			<!--	:auto-upload="true"-->
			<!--	:on-remove="handleRemove"-->
			<!--	:on-change="imgchange"-->
			<!--	:on-success="upsuccess"-->
			<!--	:multiple="false"-->
			<!--	list-type="picture">-->
			<!--	<el-button style="margin-left: 100%" type="primary">选择图片并提交</el-button>-->
			<!--</el-upload>-->

			<div style="margin-left: 25%" >
				<img :src="imgUrl" style="width: 50%"/>
				<img :src="imgUrl1" style="width: 50%"/>
			</div>
			<div style="margin-top: 5%">
				<el-button type="primary" style="width: 100%" @click="saveNewMaterial">上传周转材料</el-button>
			</div>
		</div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "New_material_turnover",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			formLabelAlign: {
				name: '',
				type: '',
				region: ''
			},
			bdst: '',
			file: '',
			imgUrl: '',
			file1: '',
			imgUrl1: ''
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/error")
		}else {
			this.bdst = this.$route.params.bdst
		}
	},
	methods: {
		handleImageCapture(event) {
			this.file = event.target.files[0];
			this.imgUrl = this.getImg(this.file); //图片地址
		},
		handleImageCaptureFile(event) {
			this.file1 = event.target.files[0];
			this.imgUrl1 = this.getImg(this.file1); //图片地址
		},
		getImg(file) {
			let url = '';
			if (window.createObjectURL !== undefined) { // basic
				url = window.createObjectURL(file);
			} else if (window.URL !== undefined) { // mozilla(firefox)
				url = window.URL.createObjectURL(file);
			} else if (window.webkitURL !== undefined) { // webkit or chrome
				url = window.webkitURL.createObjectURL(file);
			}
			return url;
		},
		saveNewMaterial(){
			if( this.formLabelAlign.material_model === '' || this.formLabelAlign.material_num === '' ||
				this.formLabelAlign.material_unit === '' ||
			this.formLabelAlign.material_model === '' || this.file === '' || this.file1 === ''){
				ElMessage.error('未填写必填项，请检查!')
			}else {
				const this_ = this
				this_.axios.post('https://www.cr12cz.cn/czscsms/up/uploadMaterial', (response) => {
					if (response.code === 200){
						this_.axios.post('https://www.cr12cz.cn/czscsms/up/uploadMaterial', (response3) => {
							if (response3.code === 200){
								this_.axios.post('/materialTurnoverMain/saveMaterialTurnover', (response1) => {
									if (response1.obj){
										this_.axios.post('/materialTurnoverPhoto/saveTurnoverPhoto', (response2) => {
											if (response2.obj){
												ElMessage.success('提交成功!')
												this.reload()
											}
										},{
											photo_url: response.obj,
											photo_url_reason: response3.obj,
											uuid: response1.obj2
										})
									}
								},{
									material_name: this.formLabelAlign.material_name,
									material_unit: this.formLabelAlign.material_unit,
									material_num: this.formLabelAlign.material_num,
									material_model: this.formLabelAlign.material_model,
									bdst: this.bdst
								})
							}
						}, {
							file: this.file1
						})
					}
				},{
					file: this.file
				})
			}
		}
	}
}
</script>

<style>

</style>